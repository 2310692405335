import React from 'react';
import {Helmet} from 'react-helmet';

const SEO_DATA = {
  description: 'The Foundation to Support the Young Kings Inc.',
  title: 'The Foundation to Support the Young Kings Inc.',
  url: '',
};

const SEO = () => {
  return (
    <Helmet>
      {/*<meta property="robots" content="noindex" />*/}

      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="description" content={SEO_DATA.description} />
      <title>{SEO_DATA.title}</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
