import React, { useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { Container } from '../../global';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

//import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

const NAV_ITEMS = [
  { text: 'Home', slug: '/' },
  { text: 'About Us', slug: 'about'},
  { text: 'Events', slug: 'events' },
  { text: 'Donate', slug: 'donate' }
];

const Navbar = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

   const toggleMobileMenu = () => {
     setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const getNavAnchorLink = item => (
    <Link to={`/${item.slug}/`} onClick={closeMobileMenu}>
      {item.text}
    </Link>
  );

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.slug)}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem.text}>{getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  const data = useStaticQuery(graphql`
    query {   
      logo: file(
        sourceInstanceName: { eq: "logos" }
        name: { eq: "logo-black-letters" }
      ) {
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Nav {...props}>
      <StyledContainer>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Link to="/"><Img fixed={data.logo.childImageSharp.fixed} /></Link>
          </Grid>
          <Grid item container xs>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <h3>The Foundation to Support the Young Kings Inc.</h3>
                <Text><SubHeaderText>Dedicated to the success of the Ron Brown College Preparatory High School</SubHeaderText></Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Mobile>
              <button onClick={toggleMobileMenu} style={{ color: 'black' }}>
                <MenuIcon />
              </button>
            </Mobile>
            <Mobile hide>{getNavList({})}</Mobile>
          </Grid>
        </Grid>
      </StyledContainer>
      <Mobile>
        {mobileMenuOpen && (
          <MobileMenu>
            <Container>{getNavList({ mobile: true })}</Container>
          </MobileMenu>
        )}
      </Mobile>
    </Nav>
  )
}

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const SubHeaderText = styled.div`
  font-size: 1.1em;
  font-style: italic;
  padding-left: 43px;
`;
  
export default Navbar;
