import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Grid from '@material-ui/core/Grid';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import GithubIcon from '@static/icons/github.svg';
import InstagramIcon from '@static/icons/instagram.svg';
import TwitterIcon from '@static/icons/twitter.svg';

const SOCIAL = [
  {
    icon: GithubIcon,
    link: 'https://github.com/ajayns/gatsby-absurd',
  },
  {
    icon: InstagramIcon,
    link: 'https://instagram.com/ajay_ns',
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/ajayns08',
  },
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {   
        logo: file(
          sourceInstanceName: { eq: "logos" }
          name: { eq: "logo-white-letters" }
        ) {
        childImageSharp {
            fixed(width: 136, height: 140) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        
        <FooterWrapper>
          <Copyright>
            <StyledContainer>
            <Grid container justify="space-between" alignItems="stretch">
              
                <Grid item lg={2} md={2} xs={12} >
                  <Img fixed={data.logo.childImageSharp.fixed} />
                </Grid>
                <Grid item lg={5} md={5} xs={12}>
                  <FooterTitle>Mission</FooterTitle>
                  <FooterText>The Foundation to Support the Young Kings, Inc., was established</FooterText>
                  <FooterText>to champion the Ron Brown College Preparatory High School.</FooterText>
                  <FooterText>Together we are dedicated to empowering D.C.'s young men of color.</FooterText>
                </Grid>
                <Grid item lg={1} md={1} xs={12}>
                  <FooterTitle>Join Us</FooterTitle>
                  <FooterText><Link to="/events/">Events</Link></FooterText>
                  {/*<FooterText><Link to="/donate/">Donate</Link></FooterText>*/}
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FooterTitle>The Foundation to Support the Young Kings, Inc.</FooterTitle>
                  <FooterText>212 Van Buren Street, NW</FooterText>
                  <FooterText>Washington, D.C. 20012</FooterText>
                  <FooterText><a href="tel:2028917219">(202) 891-7219</a></FooterText>
                  <FooterText><a href="mailto:info@ykfinc.org">info@ykfinc.org</a></FooterText>
                  
                </Grid>
              
            </Grid>
            </StyledContainer>
          </Copyright>
        </FooterWrapper>
        
      </React.Fragment>
    )}
  />
);
const FooterText = styled.div`
  font-size: 14px;
`;
const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.secondary};
  padding: 14px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.white.dark};

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: #d0d0d08a !important;
    }
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
