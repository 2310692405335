import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import SEO from '@common/SEO';
import Navbar from '@components/common/Navbar';

import Header from '@components/sections/Header';
import Footer from '@components/sections/Footer';

import theme from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <SEO />
      <GlobalStyles />
      <Navbar />
      <Wrapper>
        {children}
      </Wrapper>
      <Footer />
    </>
  </ThemeProvider>
);

const Wrapper = styled.div`
  padding-bottom: 15px;
  min-height: 66vh;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
