import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';


import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {   
        logo: file(
          sourceInstanceName: { eq: "logos" }
          name: { eq: "logo-black-letters" }
        ) {
          childImageSharp {
            fixed(width: 225, height: 225) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      
      <HeaderWrapper>
        <Container>
          {/* about summary */}
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding-top: 135px;
  padding-bottom: 15px;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 135px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const SubHeaderText = styled.div`
  font-size: 1.1em;
  font-style: italic;
  padding-left: 43px;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

export default Header;
